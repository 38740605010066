import { toastController } from '@ionic/vue';

const mixinUpdate = {
  data() {
    return {
      refreshing: false,
    };
  },

  mounted() {
    if (!navigator.serviceWorker) return;

    // listen to the event sent by registerServiceWorker.js
    document.addEventListener('swUpdated', this.updateAvailable, {
      once: true,
    });

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return;
      this.refreshing = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });
  },
  methods: {
    async updateAvailable(event) {
      const toast = await toastController.create({
        message: 'Une mise à jour de votre application est disponible',
        position: 'bottom',
        cssClass: ['green-toast-button'],
        buttons: [
          {
            text: 'Mettre à jour',
            handler: () => {
              // send a skip waiting to our service-worker.js
              // change the state of the serviceWorker to reload page
              event.detail.waiting.postMessage({ type: 'SKIP_WAITING' });
            },
          },
        ],
      });

      await toast.present();
    },
  },
};

export { mixinUpdate };
