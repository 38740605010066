import axios from 'axios';
import { getInfo } from '@/services/users';
const DRIVER_PLANNING = 'locatDriverPlanning';
let planningData = null; // Checks whether data about user planning is still stored in the application

let storedPlanning = window.localStorage.getItem(DRIVER_PLANNING);

if (storedPlanning) {
  planningData = JSON.parse(storedPlanning);
}

const DRIVER_MISSION_DETAIL = 'locatDriverMissionDetail';
let missionDetailData = {}; // We check if wa have the detail of mission is still stored in the application

let storedMissionDetail = window.localStorage.getItem(DRIVER_MISSION_DETAIL);

if (storedMissionDetail) {
  missionDetailData = JSON.parse(storedMissionDetail);
}
/**
 * Save planning data into local storage
 */


function saveData() {
  window.localStorage.setItem(DRIVER_PLANNING, JSON.stringify(planningData));
  window.localStorage.setItem(DRIVER_MISSION_DETAIL, JSON.stringify(missionDetailData));
}
/**
 * Clear data from the driver's schedule
 */


function clearMission() {
  window.localStorage.clear();
  planningData = null;
  missionDetailData = {};
}
/**
 * Retrieval of the connected driver's schedule
 * @returns {Promise<*|{}>} // All reservations, deliveries, transports and absences of this driver
 */


async function fetchPlanning() {
  const {
    token
  } = await getInfo();
  const response = await axios.get('/driver/planning', {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  planningData = response.data;
  saveData();
}

async function fetchMission(typeMission, idRide, idMissionType, idCoupon) {
  const {
    token
  } = await getInfo();
  let endpoint = '/driver/';

  if (typeMission === 'reservation') {
    endpoint += 'reservation/';
    endpoint += idCoupon ? idCoupon : idRide + '/' + idMissionType;
  } else {
    endpoint += 'transport/';
    endpoint += idCoupon ? idCoupon : idRide + '/' + idMissionType;
  }

  const response = await axios.get(endpoint, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  replaceMission(response.data);
  saveData();
  return response.data;
}

async function replaceMission(newMission) {
  for (const mission in planningData) {
    if (planningData[mission].id === newMission.id) {
      planningData[mission] = newMission;
      return;
    }
  }
}
/**
 *
 * @returns {Promise<*|{}>} // Get the last informations of mission
 */


async function fetchDetailMission(typeMission, idMissionType, idMissionItem, idCoupon) {
  const {
    token
  } = await getInfo();
  let endpoint = '/driver/';

  if (typeMission === 'reservation') {
    endpoint += 'reservation/detail/';
    endpoint += idCoupon ? 'coupon/' + idCoupon : idMissionType;
  } else {
    endpoint += 'transport/detail/';
    endpoint += idCoupon ? idCoupon : +idMissionType + '/' + idMissionItem;
  }

  const response = await axios.get(endpoint, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  if (typeMission === 'reservation') {
    missionDetailData[idMissionType] = response.data;
  } else {
    missionDetailData[idMissionType + '-' + idMissionItem] = response.data;
  }

  saveData();
}
/**
 * Recovery of the planning
 */


async function getPlanning() {
  if (!planningData) {
    await fetchPlanning();
  }

  return planningData;
}
/**
 * Returns the details of the targeted mission,
 * makes a call to the server if the details of the mission are not known
 */


async function getDetailMission(typeMission, idMissionType, idMissionItem, idCoupon = null) {
  if (typeMission === 'reservation' && !missionDetailData[idMissionType] || (typeMission === 'transfert' || typeMission === 'livraison') && !missionDetailData[idMissionType + '-' + idMissionItem] || idMissionType === 'null') {
    await fetchDetailMission(typeMission, idMissionType, idMissionItem, idCoupon);
  }

  if (typeMission === 'reservation') {
    return missionDetailData[idMissionType];
  } // if (typeMission === ('transfert' || 'livraison')) {


  return missionDetailData[idMissionType + '-' + idMissionItem];
}
/**
 * Force refresh of the schedule
 */


async function forceRefreshPlanning() {
  await fetchPlanning();
  return planningData;
}

async function forceRefreshDetailMission(typeMission, idMissionType, idMissionItem) {
  await fetchDetailMission(typeMission, idMissionType, idMissionItem);

  if (typeMission === 'reservation') {
    return missionDetailData[idMissionType];
  } // if (typeMission === ('transfert' || 'livraison')) {


  return missionDetailData[idMissionType + '-' + idMissionItem];
}
/**
 * Recovery of a mission according to its id
 */


function getMissionOrder(idMissionType, idRide, typeMission, idCoupon = null) {
  return fetchMission(typeMission, idRide, idMissionType, idCoupon);
}

export { clearMission, getPlanning, getDetailMission, getMissionOrder, forceRefreshPlanning, forceRefreshDetailMission };